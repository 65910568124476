import React from 'react'

const AbusiveBehaviour = () => {
  return (
    <div class="master-sub-container"><p></p>
<p><strong>Abusive Behaviour Policy</strong></p>
<p>We at coGuide — especially on our customer experience team — are excited to help you build the best software tools for your needs. We approach problems with your goals in mind and strive to work with you to resolve your concerns. We value and appreciate that coGuide is often a hub for your business and your life. The availability of your data is critical to your needs. However, abusive language and behaviour can make it difficult for our support team to help you find a solution. Examples of such behaviour might include denigrating language, personal attacks, threats of violence to yourself or others, and spamming, among others.</p>
<p>We look forward to helping you organize your life. To do so, we need to preserve our business and guarantee that our employees feel safe at work. To that end, when an individual or group becomes threatening or abusive to our team, coGuide may need to take action to protect our employees. These actions may include:</p>
<ul>
<li>Limiting access to coGuide support</li>
<li>Offboarding from coGuide</li>
<li>Reporting to authorities</li>
</ul>
<p>If you would like to know more about this policy or have any feedback, let us know at <a href="mailto:support@coguide.in">support@coguide.in</a>.</p>
<p class="p1"></p></div>
  )
}

export default AbusiveBehaviour