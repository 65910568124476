export const detailsData = [
  {
    title: 'coGuide’s comprehensive Evidence Generation Platform',
    desc: [
      {
        sentence: `For Planning, capturing, Maintaining and Inference of evidence throughout the lifecycle of the Product or entity or concept in question.`,
      },
      {
        sentence: `coGuide’s REAP SAAS platform allows clients to define objectives, set up required protocols, plan & configure required data points, provides for continuous data collection & management and also provide configurable real time inferential analysis dashboards which consume data, generate analysis & evidence in real time. `,
      },
      {
        sentence: `The platform also provides access to a diverse and top quality expert pool to guide with “evidence gap” & hand-holding of your evidence journey through its plug & play mentorship and collaboration modules.`,
      },
      {
        sentence: `All your evidence requirements can be planned, generated and maintained throughout the lifecycle of the target entity.`,
      },
    ],
    imgUrl: '/images/Image-2.svg',
  },
  {
    title: 'coGuide’s Data Registry Platform',
    desc: [
      {
        sentence: `Our platform allows Individuals or Groups or Institutions to create and manage their data registries with full control.`,
      },
      {
        sentence: `The platform allows clients to establish electronic registry in just five simple steps,`,
      },
      {
        sentence: `1. Define the scope of registry `,
      },
      {
        sentence: `           2. Finalise the data points`,
      },
      {
        sentence: `           3. Set-up the registry on coGuide REAP EDC.`,
      },
      {
        sentence: `           4. Invite all the collaborators & configure permissionss`,
      },
      {
        sentence: `           5. Start collecting data`,
      },
      {
        sentence: `You have just created a Data registry which can be a great asset for yourself or your organisation.Leverage it to drive evidence based practice/ research profile/ brand building/ mobilise funding and much more…`,
      },
    ],
    imgUrl: '/images/Image-3.svg',
  },
  {
    title:
      'coGuide’s Research Process Optimisation solution for accreditation and ranking ',
    desc: [
      {
        sentence: `Transform your organisational research program from fragmented, suboptimal program to highly optimised, output oriented program.`,
      },
      {
        sentence: `Easy & efficient research execution with  coGuide’s REAP can transform every resource into a prolific researcher.`,
      },
      {
        sentence: `Manage all research committees, review and approvals with ease using coGuide’s RMS `,
      },
      {
        sentence: `Monitor & optimise the research progress and research output at multi levels using process indicator and output indicator dashboards.`,
      },
      {
        sentence: `Unleash “synchronised, efficient, data driven research ecosystem” across your organisation.`,
      },
    ],
    imgUrl: '/images/Image-4.svg',
  },
  {
    title:
      'coGuide’s solution for Evidence synthesis & inference for decision making',
    desc: [
      {
        sentence: `coGuide’s product-service-expertize ecosystem to help the organizations in “evidence synthesis”.`,
      },
      {
        sentence: `Evidence synthesis in the form of systematic review meta analysis</b>, economic evaluation, big data analytics, market analytics, IP  etc. is a vital need for organizations.`,
      },
      {
        sentence: `coGuide product-service-expertize ecosystem can cater to a wide range of evidence synthesis needs. `,
      },
      {
        sentence: `coGuide expert pool has high end skills in literature search, literature screening, structured data extraction, quantitative data synthesis, economic evaluation, qualitative review, scoping review.`,
      },
      {
        sentence: `Organizations can avail the evidence synthesis as service or engage with suitable experts and organizations to undertake evidence synthesis.`,
      },
    ],
    imgUrl: '/images/Image-6.svg',
  },
  {
    title: 'Regulatory Approval & documentation',
    desc: [
      {
        sentence: `coGuide’s ecosytem provides Institutional mechanism and quality support to facilitate regulatory approvals and documentation.`,
      },
      {
        sentence: `Regulatory approvals are essential at multiple stages of product development, approval and marketing cycle.`,
      },
      {
        sentence: `coGuide has in-house CDSCO and DCGI approved Institutional review board and access to a vast network of independent Ethical committees across India.`,
      },
      {
        sentence: `coGuide has a highly skilled team with more than a decade of experience in managing the regulatory approvals for diverse products and solutions. Also has access to a wide network of teaching medical institutions, hospitals and health care practitioners and community based organizations.`,
      },
      {
        sentence: `coGuide is One stop solution for all premarket approvals (including pre-clinical testing, clinical trials, regulatory submissions, regulatory review etc.) and post marketing approvals (including post marketing surveillance, periodic regulatory reporting etc.)`,
      },
    ],
    imgUrl: '/images/Image-7.svg',
  },
  {
    title: 'coGuide Academy for Research & evidence education',
    desc: [
      {
        sentence: `coGuide academy provides access to state of the art research education using best of traditional and technology enabled learning and thereby aid organisations and individuals in achieving high level of evidence building & research skills.`,
      },
      {
        sentence: `The evidence and research skills are organized into micro skills with 4 levels of certification programmes. They are delivered through,`,
      },
      {
        sentence: `        1.Structured digital learning modules`,
      },
      {
        sentence: `        2.learning while doing through Micro encapsulated embedded videos in REAP product`,
      },
      {
        sentence: `        3.Online and off line live expert led hands-on sessions `,
      },
      {
        sentence: `        4.Structured self evaluation, peer to peer evaluation and expert evaluation of acquired skills `,
      },
      {
        sentence: `You can assess the skill level of your organization, define the desired skill level, do the segmentation of your resources to achieve different levels of skills and achieve it in a systematic and cost effective manner.`,
      },
    ],
    imgUrl: '/images/Image-5.svg',
  },
];
