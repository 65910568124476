import { Stack, Box } from '@mui/material';
import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './Ngo.css';

const imageLinks = [
  "https://go.coguide.in/c1/download/Vertical3_1_1716549003653.jpg",
  "https://go.coguide.in/c1/download/Vertical3_2_1716561677159.jpg",
  "https://go.coguide.in/c1/download/Vertical3_3_1716549117143.jpg",
  "https://go.coguide.in/c1/download/Vertical3_4_1716549137565.jpg",
  "https://go.coguide.in/c1/download/Vertical3_5_1716549164319.jpg",
  "https://go.coguide.in/c1/download/Vertical3_6_1716549183250.jpg",
  "https://go.coguide.in/c1/download/Vertical3_07_1716869055932.jpg",
  "https://go.coguide.in/c1/download/Vertical3_8_1716549218664.jpg",
];

const Ngo = () => {
  return (
    <Stack
      sx={{
        bgcolor: 'white',
      }}
    >
      <Box
        className="img-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          backgroundColor: '#e2f1ffab',
          padding: '2rem 0',
        }}
      >
        {imageLinks.map((src, index) => (
          <img
            src={src}
            alt="banner"
            style={{
              width: '80%',
              objectFit: 'contain',
            }}
            key={src}
          />
        ))}
      </Box>
      <VideoPlayer src="Home.mp4" controls={true} />
    </Stack>
  );
};

export default Ngo;
