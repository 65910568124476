import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Careers.css';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

import { EffectFade, Navigation, Pagination, Mousewheel } from 'swiper/modules';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Careers = () => {
  const [jdData, setJdData] = useState(null);

  async function getJobDescriptionData() {
    let response = await axios.get('https://dev.coguide.in/api/v1/user/jds');
    setJdData(response.data);
    console.log(response.data);
  }

  useEffect(() => {
    getJobDescriptionData();
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Typography variant="h3" sx={{
            fontSize: { xs: '2rem', md: '3rem' },
          }}>Become Part Of Our Team</Typography>
          <Box>
            {Array(4)
              .fill(true)
              .map((item, index) => (
                <FiberManualRecordRoundedIcon
                  key={index}
                  sx={{
                    color: '#005AD2',
                    fontSize: 20,
                  }}
                />
              ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '.2rem',
          }}
        >
          <Box
            sx={{
              width: { xs: '90%', md: '80%', lg: '75%' },
              height: '120vh',
            }}
          >
            <Swiper
              loop={true}
              autoplay={{
                delay: '5000',
                pauseOnMouseEnter: true,
              }}
              effect={'fade'}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[EffectFade, Navigation, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src="https://go.coguide.in/c1/download/JD-RA_1717941198118.png" />
              </SwiperSlide>
              {/* <SwiperSlide>
                <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
              </SwiperSlide> */}
            </Swiper>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <Box>
            <Typography variant="h4" sx={{
              fontSize: { xs: '2rem', md: '3rem' },
              display:'flex',
              flexDirection:'column'
            }}>
              Send Your Resume To HR{' '}
              <span
                style={{
                  color: '#005AD2',
                }}
              >
                career@coguide.in
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Careers;
