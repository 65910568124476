import React from 'react'
import { Link } from 'react-router-dom'
import './TermsAndPrivacy.css'

const TermsAndPrivacy = () => {
  return (
    <div className='terms-and-privacy-container'>
        <div className='terms-and-privacy'>
            <h1>Terms and Privacy</h1>
            <p>Summary : coGuide does not own your data, nor do we sell your personal information.</p>
        </div>
        <div className='terms-and-conditions-container'>
            <div className='terms-and-conditions'>
                <h2>Terms & Conditions</h2>
                <hr />
                <Link to="/master-subscription-agreement"><p>Master Subscription Agreement</p></Link>
                <Link to="/personal-terms-of-use"><p>Personal use terms of service</p></Link>
                <Link className='disabled'><p>Developer terms</p></Link>
                <Link to="/service-level-terms"><p>Service level terms</p></Link>
                <Link to="/marketing-program-participant-terms"><p>Marketing program participant terms</p></Link>
                <Link to="/content-gallery-guidelines-and-terms"><p>Content gallery guidelines and terms</p></Link>
            </div>
            <div className='terms-and-conditions'>
                <h2>Privacy</h2>
                <hr />
                <Link to="/privacy-policy"><p>Privacy Policy</p></Link>
                <Link to="/cookie-notice"><p>Cookie Notice</p></Link>
                <Link to="/Data-Processing-Addendum"><p>Data Processing Addendum</p></Link>
                <Link className='disabled'><p>coGuide’s List of Partners/Subprocessors</p></Link>
                <Link className='disabled'><p>GDPR</p></Link>
                <Link className='disabled'><p>Transfer Impact Assessment Information</p></Link>
            </div>
        </div>
        <div className='terms-and-conditions-container'>
            <div className='terms-and-conditions'>
                <h2>Policies</h2>
                <hr />
                <Link to="/abusive-behaviour-policy"><p>Abusive behaviour policy</p></Link>
                <Link to="/content-use-policy"><p>Content use policy</p></Link>
                <Link to="/dmca-policy"><p>DMCA policy</p></Link>
            </div>
            <div className='terms-and-conditions'>
                <h2>Other</h2>
                <hr />
                <Link className='disabled'><p>coGuide terms update summary</p></Link>
                <Link className='disabled'><p>License acknowledgements</p></Link>
            </div>
        </div>
    </div>
  )
}

export default TermsAndPrivacy