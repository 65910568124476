import React from "react";
import Dropdown from "./Dropdown";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };
  return (
    <li
      className="menu-items"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <Link
            className={`${depthLevel > 0 ? "hovering" : ""}`}
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{" "}
            {depthLevel == 0 ? (
              <span className="arrowicondown">
                <IoIosArrowDown />
              </span>
            ) : (
              <span className="arrowiconforward">
                <IoIosArrowForward />
              </span>
            )}
          </Link>
          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
          />
        </>
      ) : (
        <Link
          className={`${items.style == "cg" ? "cg" : ""} ${
            items.style == "tfr" ? "tfr" : ""
          } ${items.style == "si" ? "si" : ""}`}
          to={items.url}
          target={`${items.target == '_blank'? "_blank" : ''}`}
        >
          {items.title}
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
