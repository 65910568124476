import { Box } from '@mui/material';
import React from 'react';
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import "./VideoPlayer.css"


const VideoPlayer = ({ src, controls}) => {

  const plyrProps = {
    source: {
      type: "video",
      sources: [
        {
          src: `${process.env.PUBLIC_URL}/videos/${src}` ,
          size: 720,
        },
      ],
    },
    options: {
      autoplay: true,
      loop: { active: true },
      playsinline: false,
      muted: true,
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "fullscreen",
      ],
    },
  };


  return (
    <>
      {
        controls ? (
          <div className="video-container">
            <Plyr {...plyrProps} />
          </div>
        ) : (
          <Box
          component="section"
          bgcolor="white"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <video width="80%" height="auto" autoPlay loop muted>
            <source src={`${process.env.PUBLIC_URL}/videos/${src}`} type="video/mp4" />
          </video>
        </Box>
      )
      }
      
    </>
  );
};

export default VideoPlayer;
