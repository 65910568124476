import { Stack, Box } from '@mui/material';
import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './Startups.css';

const imageLinks = [
  "https://go.coguide.in/c1/download/Vertical4-01_1717744557116.jpg",
"https://go.coguide.in/c1/download/Vertical4-02_1717744597605.jpg",
"https://go.coguide.in/c1/download/Vertical4-03_1717744616041.jpg",
"https://go.coguide.in/c1/download/Vertical4-04_1717744634270.jpg",
"https://go.coguide.in/c1/download/Vertical4-05_1717744649603.jpg",
"https://go.coguide.in/c1/download/Vertical4-06_1717744665765.jpg",
"https://go.coguide.in/c1/download/Vertical4-07_1717744681025.jpg",
];

const Startups = () => {
  return (
    <Stack
      sx={{
        bgcolor: 'white',
      }}
    >
      <Box
        className="img-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          backgroundColor: '#e2f1ffab',
          padding: '2rem 0',
        }}
      >
        {imageLinks.map((src, index) => (
          <img
            src={src}
            alt="banner"
            style={{
              width: '80%',
              objectFit: 'contain',
            }}
            key={src}
          />
        ))}
      </Box>
      <VideoPlayer src="/videos/coguideexplainer_2.mp4" />
    </Stack>
  );
};

export default Startups;
