import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { Box, Stack } from '@mui/material';
import './HealthCare.css';

const imageLinks = [
  'https://go.coguide.in/c1/download/Vertical2_1716868867296.jpg',
  'https://go.coguide.in/c1/download/vertical2_2_1715862967260.jpg',
  'https://go.coguide.in/c1/download/vertical3_3_1715863011200.jpg',
  'https://go.coguide.in/c1/download/vertical4_4_1715863032659.jpg',
  'https://go.coguide.in/c1/download/vertical5_5_1715863072835.jpg',
  'https://go.coguide.in/c1/download/Vertical6_6_1715863095049.jpg',
  'https://go.coguide.in/c1/download/Vertical7_7_1715863112909.jpg',
  'https://go.coguide.in/c1/download/Vertical8_8_1715863130412.jpg',
  'https://go.coguide.in/c1/download/Vertical9_9_1715863151899.jpg',
];

const HealthCare = () => {
  return (
    <Stack
      sx={{
        bgcolor: 'white',
      }}
    >
      <Box
        className="img-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          backgroundColor: '#e2f1ffab',
          padding: '2rem 0',
        }}
      >
        {imageLinks.map((src, index) => (
          <img
            src={src}
            alt="banner"
            style={{
              width: '80%',
              objectFit: 'contain',
            }}
            key={src}
          />
        ))}
      </Box>
      <VideoPlayer src="HCO.mp4" controls={true} />
    </Stack>
  );
};

export default HealthCare;
