import { Box } from '@mui/material';

const Clients = ({ imgUrl }) => {
  return (
    <Box
      sx={{
        width: '100px',
        height: '50px',
      }}
    >
      <img
        className="clients-img"
        width="100px"
        height="50px"
        src={imgUrl}
        alt="Client"
      />
    </Box>
  );
};

export default Clients;
