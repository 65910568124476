import { Box, Typography } from '@mui/material';
import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          padding: '2rem 0',
          marginTop: '6rem',
          gap: '14rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-evenly',
            alignItems: { xs: 'center', md: 'flex-start' },
            maxWidth: '65%',
            margin: '0 auto',
            gap: '5rem',
          }}
        >
          <Box
            sx={{
              flexBasis: '0',
              flexGrow: '2',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`}
              height="80px"
              alt="coguide logo"
            />
            <Typography
              sx={{
                color: 'rgb(51, 51, 51)',
                fontWeight: '400',
                lineHeight: '28px',
                fontSize: '15px',
                cursor: 'pointer',
              }}
            >
              Integrated Research Suite for every researcher.
            </Typography>
          </Box>
          <Box
            sx={{
              flexBasis: '0',
              flexGrow: '1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: { xs: 'center', md: 'baseline' },
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                color: 'rgb(35, 35, 35)',
                fontWeight: '700',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            >
              PRODUCTS
            </Typography>

            <Link
              to="/educational-institutions"
              style={{
                textDecoration: 'none',
              }}
            >
              <Typography
                sx={{
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '600',
                  lineHeight: '30px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Educational Institutions
              </Typography>
            </Link>
            <Link
              to="/health-care-organizations"
              style={{
                textDecoration: 'none',
              }}
            >
              <Typography
                sx={{
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '600',
                  lineHeight: '30px',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
              >
                Health Care organizations
              </Typography>
            </Link>
            <Link
              to="/ngo"
              style={{
                textDecoration: 'none',
              }}
            >
              <Typography
                sx={{
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '600',
                  lineHeight: '16px',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
              >
                NGOs| Civil society Organizations
              </Typography>
            </Link>
            <Link
              to="/startups"
              style={{
                textDecoration: 'none',
              }}
            >
              <Typography
                sx={{
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '600',
                  lineHeight: '30px',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
              >
                Startups
              </Typography>
            </Link>
            <Link
              to="/pharma-industry"
              style={{
                textDecoration: 'none',
              }}
            >
              <Typography
                sx={{
                  color: 'rgb(51, 51, 51)',
                  fontWeight: '600',
                  lineHeight: '30px',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
              >
                Pharma industry
              </Typography>
            </Link>
          </Box>

          <Box
            sx={{
              flexBasis: '0',
              flexGrow: '2',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: { xs: 'center', md: 'baseline' },
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                color: 'rgb(35, 35, 35)',
                fontWeight: '700',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            >
              Contact Info
            </Typography>
            <Typography
              sx={{
                color: 'rgb(51, 51, 51)',
                fontWeight: '600',
                lineHeight: '30px',
                fontSize: '14px',
                cursor: 'pointer',
                textAlign: { xs: 'center', md: 'initial' },
              }}
            >
              Balaa Decision support systems private limited #302, Rama Villas,
              1st cross, HSR Layout, Bangalore, Karnataka, India-560102
            </Typography>
            <Typography
              sx={{
                color: 'rgb(51, 51, 51)',
                fontWeight: '600',
                lineHeight: '30px',
                fontSize: '14px',
                cursor: 'pointer',
                display: 'flex',
              }}
            >
              <Typography fontWeight="bold">Email: </Typography>
              <Typography>support@coguide.in</Typography>
            </Typography>
            <Typography
              sx={{
                color: 'rgb(51, 51, 51)',
                fontWeight: '600',
                lineHeight: '30px',
                fontSize: '14px',
                cursor: 'pointer',
                display: 'flex',
              }}
            >
              <Typography fontWeight="bold">Contact:</Typography>
              <Typography>+91 7624974902</Typography>
            </Typography>
            <Box
              className="footer-icons-container"
              sx={{
                display: 'flex',
                gap: '1rem',
              }}
            >
              <a href="https://www.facebook.com/coGuide.in" target='_blank' className="facebook">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a href="https://x.com/coguide_RMS" target='_blank' className="twitter">
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/coguide/" target='_blank' className="instagram">
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/c/CoGuide" target='_blank' className="youtube">
                <i class="fa-brands fa-youtube"></i>
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: { xs: '0 5%', md: '0 15%' },
          }}
        >
          <Typography
            sx={{
              color: 'rgb(51,51,51)',
              fontSize: '15px',
              fontWeight: '400',
            }}
          >
            ©2022 Balaa Decision support systems private limited. All rights
            reserved.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(https://coguide.in/wp-content/themes/anada/img/shape/1.svg)`,
            position: 'absolute',
            left: '0',
            bottom: { xs: '0', md: '0' },
            height: { xs: '700px', md: '100%' },
            width: { xs: '1000px', md: '100%' },
            zIndex: '-1',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            opacity: { xs: '0.1', md: '0.05' },
          }}
        ></Box>
      </Box>
    </>
  );
};

export default Footer;
