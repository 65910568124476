import { Box, Typography } from '@mui/material';
import React from 'react';

const Details = (detail) => {
  const title = detail.detail.title;
  const desc = detail.detail.desc;
  const imgUrl = detail.detail.imgUrl;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: { xs: '80%', md: '75%' },
          margin: '0 auto',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '1rem', md: '' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <img className="details-image" src={imgUrl} alt={title} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: { xs: '100%', md: '50%' },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: '18px', md: '24px' },
              fontWeight: '800',
            }}
          >
            {title}
          </Typography>
          {desc.map((d, index) => (
            <Typography
              sx={{
                color: 'rgb(102, 102, 102)',
                fontWeight: '400',
                lineHeight: { xs: '24px', md: '28px' },
                fontSize: { xs: '14px', md: '15px' },
              }}
            >
              {d.sentence}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Details;
