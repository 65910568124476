import './ComingSoon.css'

const ComingSoon = () => {
  return (
    <div className='coming-soon-container'>
      <p className='coming-soon'>COMING <span>SOON</span></p>
      <p className='coming-soon-desc'>We're working hard to bring you something amazing. Stay tuned!</p>
    </div>
  )
}

export default ComingSoon