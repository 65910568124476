import { Box, Stack } from '@mui/material';
import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './Educational.css';

const imageLinks = [
  'https://go.coguide.in/c1/download/vertical1_1715748281298.jpg',
  'https://go.coguide.in/c1/download/vertical2_1715748307309.jpg',
  'https://go.coguide.in/c1/download/vertical3_1715748331956.jpg',
  'https://go.coguide.in/c1/download/vertical4_1715748354185.jpg',
  'https://go.coguide.in/c1/download/vertical5_1715748376459.jpg',
  'https://go.coguide.in/c1/download/Vertical6_1715835772077.jpg',
  'https://go.coguide.in/c1/download/Vertical7_1715835801589.jpg',
  'https://go.coguide.in/c1/download/Vertical8_1715835828307.jpg',
  'https://go.coguide.in/c1/download/Vertical9_1715841410623.jpg',
];

const Educational = () => {
  return (
    <Stack
      sx={{
        bgcolor: 'white',
      }}
    >
      <Box
        className="img-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          backgroundColor: '#e2f1ffab',
          padding: '2rem 0',
        }}
      >
        {imageLinks.map((src, index) => (
          <img
            src={src}
            alt="banner"
            style={{
              width: '80%',
              objectFit: 'contain',
            }}
            key={src}
          />
        ))}
      </Box>
      <VideoPlayer src="Edu.mp4" controls={true} />
    </Stack>
  );
};

export default Educational;
