import React from 'react'

const ContentUsePolicy = () => {
  return (
    <div class="master-sub-container"><p></p>
<p><strong>Content &amp; Use Policy</strong></p>
<p>coGuide (Balaa decision support system pvt ltd), provides its software-as-a-service platform to provide teams and individuals with tools to get organized and work effectively. Our users occasionally have questions about what are permitted and prohibited uses of our platform. We have created this Content and Use Policy (the “Policy”) to provide additional clarification on activity and content which is prohibited and/or subject to additional restrictions on the platform.</p>
<p>All terms used in this Policy but not defined shall have the meaning given to them in the <a href="http://www.coguide.in">terms applicable to your subscription</a>. This policy shall be deemed incorporated into the terms applicable to your subscription and your continued use of the Services shall be subject to this Policy in addition to such terms. If you have determined that one of our customers’ use of the Services are not inline with this Policy, please contact us by reporting the page in question (instructions <a href="http://www.coguide.in">here</a>), or emailing us at <a href="mailto:team@makenotion.com">support@coguide,in</a>.</p>
<p><strong>The following activities are prohibited:</strong></p>
<ul>
<li><strong>Illegal activities</strong>: You may not use the Services to encourage, promote, facilitate or instruct others to engage in illegal activity.</li>
<li><strong>Graphic and harmful content.</strong>&nbsp;You may not use the Services to post, upload, share, store, or otherwise distribute content that is graphically violent or obscene, sexually explicit, promotes self-harm, promotes terrorist activity, promotes misinformation, or otherwise poses a threat to the public.</li>
<li><strong>Child exploitation</strong>: You may not use the Services to post, upload, share, or store content that exploits or abuses children, including but not limited to images or depictions of child abuse or sexual abuse, or that present children in a sexual manner.</li>
<li><strong>Harassment, bullying, defamation and threats</strong>: You may not use the Services to facilitate abusive, harassing, or bullying behaviour.</li>
<li><strong>Hateful content</strong>: You may not use the Services to promote, encourage, support or facilitate hate speech, violence, or discrimination based on race, color, sexual orientation, marital status, gender or identity expression, parental status, religion or creed, national origin or ancestry, sex, age, physical or mental disability, veteran status, genetic information, citizenship and/or any other characteristic protected by law. Organizations or individuals who do any of the above either through their own content or through the distribution of user generated content (whether intentionally distributed or distributed due to the failure to reasonably moderate such content) are prohibited from using the Services.</li>
<li><strong>Intellectual property</strong>: You may not use the Services to misappropriate or infringe upon anyone’s intellectual property rights.</li>
<li><strong>Malicious and deceptive practices</strong>: You may not use the Services to post, upload, share, or store malicious software, malware, viruses or other harmful code. You may not use the Services in any way that facilitates “spoofing” or “phishing” or is deceptive, false, misleading or fraudulent, including impersonating other individuals or organizations or otherwise attempting to mislead others as to the identity of the originator of content or a communication.</li>
<li><strong>Personal and confidential information</strong>: You may not post, upload, share, or store content or otherwise use the Services in a manner that is invasive of another’s privacy or otherwise violates or compromises another person’s legal rights – such as rights of privacy and publicity, including sharing another’s personally identifiable information, sensitive personal information, or confidential information without the appropriate consent (proof of which may be requested).</li>
<li><strong>Interference with the Services.</strong>&nbsp;You may not use the Services to interfere with or otherwise negatively impact any aspect of the Services or any third-party products or services that are integrated or connected to the Services.</li>
<li><strong>Content subject to additional scrutiny.</strong>&nbsp;Our goal is to ensure the availability and reliability of the Services. We have determined that content related to certain industries may have higher abuse rates that may jeopardize the availability for a broad range of users of the Services. We reserve the right to provide additional scrutiny on, suspend the use of and/or discontinue the provision of the Services to individuals and organizations who use the Services related to such industries. Examples of such industries include:
<ul>
<li><a href="https://www.consumer.ftc.gov/features/pass-it-on/work-at-home-scams">work-at-home</a>, make money online, and lead generating opportunities</li>
<li>gambling services or products;</li>
<li>adult entertainment;</li>
<li>sales of products in high demand due to regional, national, or international emergencies;</li>
<li>online trading, day trading tips, or similar related content; and</li>
<li>multi-level marketing or affiliate marketing.</li>
</ul>
</li>
</ul>
<p>You may contact us regarding the Service or these Terms at: coGuide #401, Rama villas, 1<sup>st</sup> cross, Reliable Residency layout, HSR, Sector-2, Bangalore – 560102 or via email to <a href="mailto:support@coguide.in">support@coguide.in</a>.</p>
<p>Last updated: September 25, 2022</p>
<p class="p1"></p></div>
  )
}

export default ContentUsePolicy