export const clientsData = [
  {
    imgUrl: '/images/fernandez-logo.png',
  },
  {
    imgUrl: '/images/dhanalakshmi3.jpeg',
  },
  {
    imgUrl: '/images/ghri.jpeg',
  },
  {
    imgUrl: '/images/logo.png.webp',
  },
  {
    imgUrl: '/images/krish.jpeg',
  },
  {
    imgUrl: '/images/miror-logo.webp',
  },
  {
    imgUrl: '/images/advaitlogo.jpeg',
  },
  {
    imgUrl: '/images/Rainbow_Logo.svg',
  },
  {
    imgUrl: '/images/garbhagudi.jpeg',
  },
];
