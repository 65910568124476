import React from 'react'

const ContentGallery = () => {
  return (
    <div class="master-sub-container"><p></p>
<p><strong>Template Gallery Guidelines and Terms</strong></p>
<p>The Template Gallery is a place to share your own work with other coGuide users. Use of the template gallery is subject to coGuide’s <a href="https://coguide.in">Master Subscription Agreement</a> and <a href="https://coguide.in">Personal Use Terms of Services</a> (as applicable), <a href="https://coguide.in">Privacy Policy</a>, and <a href="https://coguide.in">Content and Use Policy</a>. By Using the Template Gallery you also agree to abide by and follow these Template Gallery Guidelines and Terms (“Gallery Guidelines”). Any terms used below shall have the same meaning as defined in these agreements.</p>
<p><strong>Ownership and Intellectual Property Rights</strong></p>
<p>If you are a creator of a template, you own and retain ownership rights to any User Content or Customer Data (as applicable) included in the templates you make available; provided that at all times coGuide shall retain full ownership of the Services and any and all functionality that you use to generate such template. As between Users of coGuide that download templates from the Template Gallery and coGuide, templates are Non-coGuide Applications.</p>
<p>Please respect the intellectual property rights of others and do not post anything that you don’t have the rights to post. By making a template available in the coGuide Template Gallery, you represent and warrant that you have the rights and/or permission to make such template available to others. You hereby grant coGuide a license to access, use, modify, and distribute your template. You hereby grant Users that duplicate your template a non-exclusive license to access, modify, and use such template for so long as they retain an active Subscription to the Services. Reposting of templates purchased from other creators is strictly prohibited.</p>
<p><strong>Inappropriate content</strong></p>
<p>We are building a welcoming and inclusive community. coGuide retains full discretion&nbsp;to not publish any content that is not appropriate.</p>
<p>We don’t allow any of the following in the Template Gallery:</p>
<ol>
<li>Pornography or sexually explicit or obscene material</li>
<li>Depictions of minors in a sexual manner</li>
<li>Hate speech or highly offensive language</li>
<li>Harassment or threats</li>
<li>Graphic violence</li>
<li>Illegal activities or goods or promotion of illegal activities or goods</li>
<li>Self-harm or the promotion of self-harm</li>
<li>Promotion of terrorism or violent extremism</li>
</ol>
<p><strong>Spam</strong></p>
<ol>
<li>Do not post advertisements; and</li>
<li>Do not link to spammy websites</li>
</ol>
<p><strong>Privacy</strong></p>
<p>Unless you have appropriate consent, please ensure that your template does not contain personal information or sensitive data such as:</p>
<ol>
<li>Phone numbers</li>
<li>Private email addresses</li>
<li>Physical addresses</li>
<li>Credit card, bank account, or social security information</li>
<li>Passwords</li>
</ol>
<p><strong>Modifications</strong></p>
<p>We reserve the right, at our sole discretion, to modify or replace these Gallery Guidelines at any time. By continuing to access or use the Template Gallery after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Template Gallery.</p>
<p><strong>Reporting Violations</strong></p>
<p>See something that doesn’t follow the Gallery Guidelines? Write us at <a href="mailto:support@coguide.in">support@coguide.in</a>.</p>
<p class="p1"></p></div>
  )
}

export default ContentGallery