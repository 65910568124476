import './App.css';
import MenuAppBar from './components/menu/MenuAppBar';
import Evidence from './components/evidence/Evidence.jsx';
import { Routes, Route, useLocation } from 'react-router-dom';
import OurTeam from './components/teams/OurTeam.jsx';
import HomePage from './components/home/HomePage.jsx';
import Footer from './components/footer/Footer.jsx';
import HealthCare from './components/Health Care organizations/HealthCare.jsx';
import Educational from './components/Educational Institutions/Educational.jsx';
import Ngo from './components/NGOs/Ngo.jsx';
import PharmaIndustry from './components/Pharma industry/PharmaIndustry.jsx';
import Startups from './components/Startups/Startups.jsx';
import { useEffect } from 'react';
import Careers from './components/careers/Careers.jsx';
import Navbar from './components/CustomNavbar/Navbar.jsx'
import ComingSoon from './components/Coming Soon/ComingSoon.jsx';
import TermsAndPrivacy from './components/Terms and privacy/TermsAndPrivacy.jsx';
import MasterSubscriptionAgreement from './components/Terms and privacy/Terms and privacy pages/MasterSubscriptionAgreement.jsx';
import PersonalTermsOfUse from './components/Terms and privacy/Terms and privacy pages/PersonalTermsOfUse.jsx';
import ServiceLevelTerms from './components/Terms and privacy/Terms and privacy pages/ServiceLevelTerms.jsx';
import MarketingProgram from './components/Terms and privacy/Terms and privacy pages/MarketingProgram.jsx';
import ContentGallery from './components/Terms and privacy/Terms and privacy pages/ContentGallery.jsx';
import PrivacyPolicy from './components/Terms and privacy/Terms and privacy pages/PrivacyPolicy.jsx';
import CookieNotice from './components/Terms and privacy/Terms and privacy pages/CookieNotice.jsx';
import DataProcessing from './components/Terms and privacy/Terms and privacy pages/DataProcessing.jsx';
import AbusiveBehaviour from './components/Terms and privacy/Terms and privacy pages/AbusiveBehaviour.jsx';
import ContentUsePolicy from './components/Terms and privacy/Terms and privacy pages/ContentUsePolicy.jsx';
import DmcaPolicy from './components/Terms and privacy/Terms and privacy pages/DmcaPolicy.jsx';

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/evidence" element={<Evidence />} />
        <Route path="/teams" element={<OurTeam />} />
        <Route path="/health-care-organizations" element={<HealthCare />} />
        <Route path="/educational-institutions" element={<Educational />} />
        <Route path="/ngo" element={<Ngo />} />
        <Route path="/pharma-industry" element={<PharmaIndustry />} />
        <Route path="/startups" element={<Startups />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
        {/* Terms and privacy routes */}
        <Route path="/master-subscription-agreement" element={<MasterSubscriptionAgreement />} />
        <Route path="/personal-terms-of-use" element={<PersonalTermsOfUse />} />
        <Route path="/service-level-terms" element={<ServiceLevelTerms />} />
        <Route path="/marketing-program-participant-terms" element={<MarketingProgram />} />
        <Route path="/content-gallery-guidelines-and-terms" element={<ContentGallery />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-notice" element={<CookieNotice />} />
        <Route path="/Data-Processing-Addendum/" element={<DataProcessing />} />
        <Route path="/abusive-behaviour-policy" element={<AbusiveBehaviour />} />
        <Route path="/content-use-policy" element={<ContentUsePolicy />} />
        <Route path="/dmca-policy" element={<DmcaPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
