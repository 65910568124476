import React, { useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate  } from "react-router-dom";
import MobileDropdown from "./MobileDropdown";

const MobileMenuItems = ({ items, depthLevel,setIsOpened }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  const navigate  = useNavigate ();

  useEffect(() => {
    const handler = (event) => {
     if (dropdown && ref.current && !ref.current.contains(event.target)) {
      setDropdown(false);
     }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
     // Cleanup the event listener
     document.removeEventListener("mousedown", handler);
     document.removeEventListener("touchstart", handler);
    };
   }, [dropdown]);

   const handleClick = (event) => {
    event.preventDefault(); 
    setIsOpened(false);
    if(items.url[0] === '/'){
      navigate(items.url);
    }else{
      window.location.href = items.url;
    }
  };
  return (
    <>
    <li
      className="mobile-menu-items"
      ref={ref}
    >
      {items.submenu ? (
        <>
          <Link
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={()=> setDropdown((prev) => !prev)}
          >
            {items.title}{" "}
              <span className="arrowicondown">
                <IoIosArrowDown />
              </span>
          </Link>
          <MobileDropdown
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
            setIsOpened={setIsOpened}
          />
        </>
      ) : (
        <Link
          className={`${items.style == "cg" ? "mobile-cg" : ""} ${
            items.style == "tfr" ? "mobile-tfr" : ""
          } ${items.style == "si" ? "mobile-si" : ""}`}
          to={items.url}
          target={`${items.target == '_blank'? "_blank" : ''}`}
          onClick={handleClick}
        >
          {items.title}
        </Link>
      )}
    </li>
    </>
  );
};

export default MobileMenuItems;
