import React from 'react'

const ServiceLevelTerms = () => {
  return (
    <div class="master-sub-container"><p></p>
<p><strong>Service Level Terms</strong></p>
<p>The Services shall be available 99.9%, measured monthly, excluding holidays and weekends and scheduled maintenance (“Availability”). If Customer requests maintenance during these hours, any Availability calculation will exclude periods affected by such maintenance. coGuide will notify the Customer of any scheduled maintenance through <a href="https://status.notion.so/">https://status.coGuide.in/</a>. Any downtime resulting from reasons beyond coGuide’s control, including any Force Majeure Event, will also be excluded from Availability calculations.</p>
<p>Customer’s sole and exclusive remedy, and coGuide’s entire liability, in connection with the failure to meet the Availability requirements (“Downtime”) is to receive a 2% credit of the Subscription Fees for each period of 30 or more consecutive minutes of Downtime provided that (1) the Downtime lasts longer than 24 hours, and (2) no more than one such credit will accrue per day. Downtime shall begin to accrue as soon as Customer recognises that Downtime is taking place and continues until the Availability of the Services is restored. In order to receive credit, Customer must notify coGuide in writing within 72 hours from the time of Downtime, and failure to provide such notice will forfeit the right to receive credit. Such credit may not be redeemed for cash. The credits may not cumulatively be applied to exceed one week of Subscription Fees in any given month. coGuide will only apply a credit to the month in which the incident occurred.</p>
<p class="p1"></p></div>
  )
}

export default ServiceLevelTerms