export const menuItemsData = [
    {
      title: "HOME",
      url: "/",
      style:'home'
    },
    {
      title: "PRODUCTS",
      url: "/",
      submenu: [
        {
          title: "Educational Institutions",
          url: "/educational-institutions",
          style:'cg'
        },
        {
          title: "Health Care organizations",
          url: "/health-care-organizations",
          style:'cg'
        },
        {
          title: "NGOs| Civil society Organizations",
          url: "/ngo",
          style:'cg'
        },
        {
          title: "Startups",
          url: "/startups",
          style:'cg'
        },
        {
          title:'Pharma industry',
          url:'/pharma-industry',
          style:'cg',
        }
      ],
    },
    {
      title: "RESOURCES",
      url: "/comingsoon",
      submenu:[
        {
          title:'Video resources',
          url:'/comingsoon',
          style:'cg',
          submenu:[
            {
              title:'Research learning',
              url:'/comingsoon',
              style:'cg',
            },
            {
              title:'Product videos ',
              url:'/comingsoon',
              style:'cg',
            },
            {
              title:'Event videos ',
              url:'/comingsoon',
              style:'cg',
            },
            {
              title:'Video testimonials ',
              url:'/comingsoon',
              style:'cg',
            },
          ]
        },
        {
          title:'Blogs',
          url:'/comingsoon',
          style:'cg'
        },
        {
          title:'coGuide academy',
          url:'/comingsoon',
          style:'cg',
          submenu:[
            {
              title:'CREW (coGuide Research webinars)',
              url:'/comingsoon',
              style:'cg',
            },
            {
              title:'CPRL (Coguide Practical research lessons)',
              url:'/comingsoon',
              style:'cg',
            },
            {
              title:'CCP (CoGuide Research Certification Programs)',
              url:'/comingsoon',
              style:'cg',
            },
            {
              title:'CIRI (Coguide integrated research internship)',
              url:'/comingsoon',
              style:'cg',
            },
          ]
        },
        {
          title:'Social Stock Exchange(SSE)',
          url:'/comingsoon',
          style:'cg'
        },
        {
          title:'Reporting Tools',
          url:'/comingsoon',
          style:'cg'
        },
        {
          title:'Official',
          url:'/terms-and-privacy',
          style:'cg'
        },
      ]
    },
    {
      title: "OUR TEAM",
      url: "/teams",
    },
    {
      title: "EVIDENCE",
      url: "/evidence",
    },
    {
      title: "CAREERS",
      url: "/careers",
    },
    {
      title: "Try For Free",
      url: "https://reapv2.coguide.in/register",
      style:'tfr',
      target:'_blank'
    },
    {
      title: "Sign In",
      url: "https://reapv2.coguide.in/login",
      style:'si',
      target:'_blank'
    },
  ];
  