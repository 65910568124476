
import MobileMenuItems from "./MobileMenuItems";

const MobileDropdown = ({ submenus, dropdown, depthLevel,setIsOpened }) => {
  depthLevel = depthLevel + 1;
  return (
    <ul className={`mobile-dropdown ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <>
          <MobileMenuItems items={submenu} key={index} depthLevel={depthLevel} setIsOpened={setIsOpened}/>
        </>
      ))}
    </ul>
  );
};

export default MobileDropdown;
