import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import { menuItemsData } from "../../data/menuItemsData.js";
import MenuItems from "./MenuItems";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import MobileMenuItems from "./MobileMenuItems.jsx";
const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const depthLevel = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav className={isScrolled ? "navbar scrolled" : "navbar"}>
      <Box sx={{
        display: { xs: 'flex', md: 'none' }
      }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => {
            setIsOpened((prevstate)=>!prevstate);
          }}
          color="black"
        >
          {isOpened ? <CloseIcon/> : <MenuIcon />}
        </IconButton>
        {isOpened &&
          <ul className="menus-mobile">
            {menuItemsData.map((menu, index) => {
              return (
                <MobileMenuItems items={menu} key={index} depthLevel={depthLevel} setIsOpened={setIsOpened}/>
              );
            })}
          </ul>
        }
      </Box>
      <div className="logo">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`}
            alt="coguide logo"
          />
        </Link>
      </div>
      <Box className="navbar-menu" sx={{
        display: { xs: 'none', md: 'flex' }
      }}>
        <ul className="menus">
          {menuItemsData.map((menu, index) => {
            return (
              <MenuItems items={menu} key={index} depthLevel={depthLevel} />
            );
          })}
        </ul>
      </Box>
      
    </nav>
  );
};

export default Navbar;
